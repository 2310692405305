<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <UserList :isUser="true" :isSuper="true"></UserList>
    </div>
  </div>

</template>

<script>
import UserList from './components/userList'
export default {
  name: "listView",
  data(){
    return{
    
    }
  },
  components:{
    UserList
  },
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
